import { Link } from "gatsby";
import parse from "html-react-parser";
import React from "react";
import "react-phone-number-input/style.css";
import * as s from "./GlobalBannerBlock.module.css";

const GlobalBannerBlock = ({ block }) => {
  const image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };

  let blockStyles = {};
  if (!!image.data) {
    blockStyles["backgroundImage"] = `url(${image.data.images.fallback.src})`;
  }
  if (!!block.bgColor) {
    blockStyles["backgroundColor"] = block.bgColor;
  }
  if (!!block.borderRaduis) {
    blockStyles["borderRadius"] = block.borderRaduis;
  }

  let titleStyles = {};
  if (!!block.titleColor) {
    titleStyles["--title-color"] = block.titleColor;
    titleStyles["color"] = block.titleColor;
  }

  let buttonStyles = {};
  if (!!block.buttonTextColor) {
    buttonStyles["color"] = block.buttonTextColor;
  }
  if (!!block.buttonBg) {
    buttonStyles["backgroundColor"] = block.buttonBg;
  }

  return (
    <>
      <section className={`${s.bannerBlock} ${'small' == block.layout ? s.small : ''}`}>
        <div className={`${s.containerFluid} container-fluid container-md`}>
          <div className="row">
            <div className={`px-3 ${block.classes ? block.classes : ''}`}>
              <div className={`d-flex justify-content-center flex-column ${s.backgroundBlock}`} style={blockStyles}>
                {!!block.title &&
                  <div className="text-center" style={titleStyles}>{parse(block.title)}</div>
                }
                {!!block.buttonPage &&
                  <div className="text-center">
                    <Link
                      to={block.buttonPage.uri}
                      className={`${s.btn}`}
                      style={buttonStyles}
                    >{!block.buttonText ? block.buttonPage.title : block.buttonText}</Link>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GlobalBannerBlock;
