import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./ServicesHeroBlock.module.css";

const ServicesHeroBlock = ({ block }) => {
  const image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
  };
  const imageMobile = {
    data: block.imageMobile?.localFile?.childImageSharp?.gatsbyImageData,
  };

  let blockStyles = {};
  if (!!image.data) {
    blockStyles["--servicesHeroBock-desktop-bg"] = `url(${image.data.images.fallback.src})`;
  }
  if (!!imageMobile.data) {
    blockStyles["--servicesHeroBock-mobile-bg"] = `url(${imageMobile.data.images.fallback.src})`;
  }

  return (
    <>
      <section className={`${s.servicesHeroBlock} d-flex`} style={blockStyles}>
        <div className="container">
          <div className={`${s.row} row align-items-center`}>
            <div className="col-12 col-xl-6">
              {!!block.title &&
                <div className={`${s.title}`}>{parse(block.title)}</div>
              }
              {!!block.content &&
                <div className={`${s.content}`}>{parse(block.content)}</div>
              }
              <div className={``}>
                {!!block.buttonPage &&
                  <Link
                    to={block.buttonPage.uri}
                    className={`${s.topBlockButton}`}
                  >{!block.buttonText ? block.buttonPage.title : block.buttonText}</Link>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesHeroBlock;
