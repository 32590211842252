import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./ServiceWorkProcessItem.module.css";

const ServicesWorkProcessItem = ({ item, index }) => {

    const funnelDigit = 76;

    let scale = 1.1;
    let titleFontSize = '12.5342px';
    let serviceFontSize = '12.5342px';
    let serviceScale = 1;

    switch (index) {
        case 5:
            titleFontSize = '56.7027px';
            break;
        case 4:
            titleFontSize = '39.7577px';
            break;
        case 3:
            titleFontSize = '38.6144px';
            break;
        case 2:
            scale = 1.5;
            titleFontSize = '37.3652px';
            break;
        case 1:
            scale = 1.5;
            titleFontSize = '17.4067px';
            break;
        case 0:
            scale = 1.8;
            serviceFontSize = '8.28768px';
            serviceScale = 0.6;
            break;
        default:
            scale = 1.1;
            break;
    }

    let styles = {
        '--funnel-item-backgroundColor': item.bgColor,
        '--funnel-item-color': item.textColor,
        '--funnel-item-width': `${funnelDigit}px`,
        '--funnel-item-index': index,
        '--funnel-item-hoverScale': scale,
        '--funnel-item-titleFontSize': titleFontSize,
        '--funnel-item-serviceFontSize': serviceFontSize,
        '--funnel-item-serviceScale': serviceScale,
    }


    return (
        <div className={`${s.item}`} style={styles}>
            <span className={`${s.title}`}>{item.text}</span>
            <div className={`${s.services}`}>
                {!!item.services?.length &&
                    item.services.map((sitem, i) => {
                        let data = sitem.service?.acf_services?.cardBlock;
                        let logo = {
                            data: data.logo?.localFile?.childImageSharp?.gatsbyImageData,
                            alt: data.logo?.altText || ``,
                        };
                        return <div key={`service-item-${i}`} className="d-flex flex-column align-items-center">
                            {!!logo.data &&
                                <div className={`${s.serviceIconBlock}`}>
                                    <GatsbyImage
                                        loading="eager"
                                        placeholder="dominantColor"
                                        image={logo.data}
                                        alt={logo.alt}
                                        width={24}
                                        height={24}
                                        className={`${s.serviceIconImage}`} />
                                </div>
                            }
                            <h3 className={`${s.serviceTitle}`}>{!!data.title ? data.title : item.title}</h3>
                        </div>
                    })
                }
            </div>
        </div>
    );
};

export default ServicesWorkProcessItem;
