import { GatsbyImage } from 'gatsby-plugin-image';
import parse from "html-react-parser";
import React from "react";
import * as s from "./FMPInfoBlock.module.css";

const FMPInfoBlock = ({ block }) => {

  const getItemLayout = (item, index) => {
    let image = {
      data: item.image?.localFile?.childImageSharp?.gatsbyImageData,
      alt: item.image?.altText || ``,
    };
    return <div className={`${s.serviceWrapper} row align-items-center`}
      key={`fmp-service-item-${index}`}>
      <div className={`col-md-6 d-md-flex align-items-center ${index % 2 ? 'order-1 order-md-2' : ''}`}>
        <div className={`${s.serviceItem}`}>
          {!!item.title &&
            <h3>{parse(item.title)}</h3>
          }
          {!!item.content &&
            <div className={`${s.serviceDesc}`}>{parse(item.content)}</div>
          }
          {item.types?.length > 0 &&
            <div className={`d-flex flex-wrap ${s.categories}`}>
              {item.types?.map((t, i) => {
                return <p key={`fmp-service-type-${index}-${i}`}>{t.name}</p>
              })}
            </div>
          }
        </div>
      </div>
      <div className={`col-md-6 ${index % 2 ? 'order-2 order-md-1' : ''}`}>
        <div className={`${s.imageBlock}`}>
          {!!image.data &&
            <GatsbyImage
              loading="eager"
              placeholder="dominantColor"
              image={image.data}
              alt={image.alt}
              className={`mw-100`} />
          }
        </div>
      </div>
    </div>
  };

  return (
    <>
      {!!block.items &&
        <section className={`${s.freeMarketingServices}`}>
          <div className="container">
            {block.items?.length > 0 &&
              block.items?.map((item, index) => {
                return getItemLayout(item, index);
              })
            }
          </div>
        </section>
      }
    </>
  );
};

export default FMPInfoBlock;
