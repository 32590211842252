import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./CareersBenefitsBlock.module.css";

const CareersBenefitsBlock = ({ block }) => {
  const image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };

  const imageMobile = {
    data: block.imageMobile?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.imageMobile?.altText || ``,
  };

  return (
    <>
      <section className={`${s.ourBenefits}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 d-none d-md-block">
              <div className={`${s.galleryBlock}`}>
                {/* <img src="./img/Group 48095432.png" alt="" className="mw-100" /> */}
                {!!image.data &&
                  <GatsbyImage
                    loading="eager"
                    placeholder="dominantColor"
                    image={image.data}
                    alt={image.alt}
                    className={`mw-100`} />
                }
              </div>
            </div>
            <div className="col-lg-5">
              <div className="d-flex h-100 align-items-center">
                <div>
                  {!!block.title &&
                    parse(block.title)
                  }

                  {!!block.content &&
                    parse(block.content)
                  }
                  {/* <p>We serve many customers, ranging from small businesses, medium entrepreneurs, to
                    world-renowned companies. Their satisfaction is our pleasure. We strive to provide
                    the best
                    service by:</p> */}
                  {block.items?.length > 0 &&
                    <ul>
                      {block.items?.map((item, i) => {
                        return <li key={`info-item-${i}`}>{item.title}</li>
                      })}
                    </ul>
                  }
                  {/* <ul>
                    <li>Flexible Working Hours</li>
                    <li>Fully Remote </li>
                    <li>Unlimited PTO</li>
                    <li>International Team</li>
                    <li>Individualized Professional Growth</li>
                    <li>Flexible Performance Review Process</li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="col-12 d-md-none">
              <div className={`${s.galleryBlock}`}>
                {/* <img src="./img/Group 48095433.png" alt="mw-100" /> */}
                {!!imageMobile.data &&
                  <GatsbyImage
                    loading="eager"
                    placeholder="dominantColor"
                    image={imageMobile.data}
                    alt={imageMobile.alt}
                    className={`mw-100`} />
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareersBenefitsBlock;
