import parse from "html-react-parser";
import React from "react";
import * as s from "./ServicesWorkProcess.module.css";
import ServicesWorkProcessItem from "./ServiceWorkProcessItem";

const ServicesWorkProcess = ({ block }) => {

  let mobileGif = {
    alt: block.mobileGif?.altText || ``,
    url: block.mobileGif?.localFile?.url,
  }

  return (
    <>
      <section className={`${s.servicesWorkProcess}`}>
        <div className="container">
          <div className="col-12">
            {!!block.title &&
              <div className={`${s.title}`}>{parse(block.title)}</div>
            }
          </div>
          <div className="col-12 col-xl-10 col-xxl-8">
            {!!block.content &&
              <div className={`${s.content}`}>{parse(block.content)}</div>
            }
          </div>
          <div className="col-12">
            <div className="d-none d-lg-block">
              {!!block.steps?.length &&
                block.steps?.map((item, i) => {
                  return <ServicesWorkProcessItem key={`funnel-item-${i}`} item={item} index={block.steps?.length - i - 1} />
                })
              }
            </div>
            {!!mobileGif.url &&
              <img src={mobileGif.url} alt={mobileGif.alt} className="d-block d-lg-none mx-auto mw-100" />
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesWorkProcess;
