import { graphql } from "gatsby"
import parse from "html-react-parser"
import React from "react"


// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../assets/css/@wordpress/block-library/build-style/style.css"
import "../assets/css/@wordpress/block-library/build-style/theme.css"
import AboutCtaBlock from './../components/AboutCtaBlock/AboutCtaBlock'
import AboutHeroBlock from "./../components/AboutHeroBlock/AboutHeroBlock"
import CareersBasedBlock from './../components/CareersBasedBlock/CareersBasedBlock'
import CareersBelieveBlock from './../components/CareersBelieveBlock/CareersBelieveBlock'
import CareersBenefitsBlock from './../components/CareersBenefitsBlock/CareersBenefitsBlock'
import CareersHeroBlock from './../components/CareersHeroBlock/CareersHeroBlock'
import ContactFormBlock from './../components/ContactFormBlock/ContactFormBlock'
import Faq from './../components/faq/faq'
import FMPHeroBlock from './../components/FMPHeroBlock/FMPHeroBlock'
import FMPInfoBlock from './../components/FMPInfoBlock/FMPInfoBlock'
import GlobalBannerBlock from './../components/GlobalBannerBlock/GlobalBannerBlock'
import GlobalFormBlock from "./../components/GlobalFormBlock/GlobalFormBlock"
import GlobalFounderBlock from './../components/GlobalFounderBlock/GlobalFounderBlock'
import GlobalMissionVisionBlock from './../components/GlobalMissionVisionBlock/GlobalMissionVisionBlock'
import GlobalQuizBlock from './../components/GlobalQuizBlock/GlobalQuizBlock'
import GlobalServicesBlock from "./../components/GlobalServicesBlock/GlobalServicesBlock"
import GlobalTeamBlock from './../components/GlobalTeamBlock/GlobalTeamBlock'
import GlobalVacanciesBlock from './../components/GlobalVacanciesBlock/GlobalVacanciesBlock'
import HomeHeroBlock from './../components/HomeHeroBlock/HomeHeroBlock'
import HomeTestimonialsBlock from './../components/HomeTestimonialBlock/HomeTestimonialsBlock'
import Layout from "./../components/layout"
import Seo from "./../components/seo"
import ServicesHeroBlock from './../components/ServicesHeroBlock/ServicesHeroBlock'
import ServicesPartnersBlock from './../components/ServicesPartnersBlock/ServicesPartnersBlock'
import ServicesWorkProcess from "./../components/ServicesWorkProcess/ServicesWorkProcess"
import TeamMediaBlock from './../components/TeamMediaBlock/TeamMediaBlock'
import TeamTeamBlock from './../components/TeamTeamBlock/TeamTeamBlock'

const PageTemplate = ({ data: { page }, location }) => {

  // const vacancyRef = React.useRef(null);
  const [vacancyRef, setVacancyRef] = React.useState(null);
  const [quizRef, setQuizRef] = React.useState(null);
  // scroll to ref example function
  const scrollToRef = (ref) => {
    ref?.scrollIntoView({ behavior: "smooth" });
  };

  // console.log(location);

  return (
    <Layout>
      <Seo
        title={page.title}
        description={""}
        pageSeo={page}
        location={location}
      />
      {
        !!page.acf_content.acfContent &&
        page.acf_content.acfContent.map((block, index) => {
          switch (block.__typename) {
            case 'WpPage_AcfContent_AcfContent_GlobalMissionVisionBlock':
              return <GlobalMissionVisionBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_GlobalServicesBlock':
              return <GlobalServicesBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_GlobalTeamBlock':
              return <GlobalTeamBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_GlobalFounderBlock':
              return <GlobalFounderBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_GlobalBannerBlock':
              return <GlobalBannerBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_GlobalVacanciesBlock':
              return <GlobalVacanciesBlock block={block} key={`block-${index}`}
                ref={newRef => setVacancyRef(newRef)} />
              break;
            case 'WpPage_AcfContent_AcfContent_HomeHeroBlock':
              return <HomeHeroBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_HomeTestimonialsBlock':
              return <HomeTestimonialsBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_AboutHeroBlock':
              return <AboutHeroBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_GlobalFormBlock':
              return <GlobalFormBlock block={block} key={`block-${index}`} pageTitle={page.title} />
              break;
            case 'WpPage_AcfContent_AcfContent_AboutCtaBlock':
              return <AboutCtaBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_ContactFormBlock':
              return <ContactFormBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_TeamMediaBlock':
              return <TeamMediaBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_TeamTeamBlock':
              return <TeamTeamBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_ServicesHeroBlock':
              return <ServicesHeroBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_ServicesPartnersBlock':
              return <ServicesPartnersBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_ServicesWorkProcess':
              return <ServicesWorkProcess block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_FmpHeroBlock':
              return <FMPHeroBlock block={block} key={`block-${index}`} scrollToRef={scrollToRef} targetRef={quizRef} />
              break;
            case 'WpPage_AcfContent_AcfContent_FmpInfoBlock':
              return <FMPInfoBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_FaqBlock':
              return <Faq block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_GlobalQuizBlock':
              return <GlobalQuizBlock block={block} key={`block-${index}`} ref={newRef => setQuizRef(newRef)} />
              break;
            case 'WpPage_AcfContent_AcfContent_CareersHeroBlock':
              return <CareersHeroBlock block={block} key={`block-${index}`} scrollToRef={scrollToRef} targetRef={vacancyRef} />
              break;
            case 'WpPage_AcfContent_AcfContent_CareersBasedBlock':
              return <CareersBasedBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_CareersBelieveBlock':
              return <CareersBelieveBlock block={block} key={`block-${index}`} />
              break;
            case 'WpPage_AcfContent_AcfContent_CareersBenefitsBlock':
              return <CareersBenefitsBlock block={block} key={`block-${index}`} />
              break;
            default:
              return <section className="container" style={{ 'wordBreak': 'break-word' }} key={`block-${index}`}>
                {block.__typename}
                {/* {JSON.stringify(block, null ,2)} */}
                <hr />
              </section>
          }
        })
      }
      <section className="container">
        {!!page.content && (
          <section itemProp="articleBody">{parse(page.content)}</section>
        )}
      </section>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageById(
    $id: String!
  ) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            url
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
      acf_content {
        acfContent {
          ... on WpPage_AcfContent_AcfContent_GlobalMissionVisionBlock {
            __typename
            blockId
            mission
            vision
          }
          ... on WpPage_AcfContent_AcfContent_GlobalServicesBlock {
            __typename
            blockId
            title
            subtitle
            services {
              fieldGroupName
              service {
                __typename
                ... on WpService {
                  databaseId
                  uri
                  title
                  excerpt
                  acf_services {
                    cardBlock {
                      color
                      description
                      title
                      logo {
                        mediaItemUrl
                        localFile {
                          childImageSharp {
                              gatsbyImageData
                          }
                        }
                        altText
                      }
                      hoverLogo {
                        mediaItemUrl
                        localFile {
                          childImageSharp {
                              gatsbyImageData
                          }
                        }
                        altText
                      }
                    }
                  }
                  featuredImage {
                    node {
                      __typename
                      ... on WpMediaItem {
                        mediaItemUrl
                        localFile {
                          childImageSharp {
                              gatsbyImageData
                          }
                        }
                        altText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_GlobalVacanciesBlock {
            __typename
            blockId
            title
            items {
              fieldGroupName
              item {
                __typename
                ... on WpVacancy {
                  databaseId
                  uri
                  title
                  excerpt
                  acf_vacancies {
                    cardBlock {
                      color
                      description
                      title
                      logo {
                        mediaItemUrl
                        localFile {
                          childImageSharp {
                              gatsbyImageData
                          }
                        }
                        altText
                      }
                      hoverLogo {
                        mediaItemUrl
                        localFile {
                          childImageSharp {
                              gatsbyImageData
                          }
                        }
                        altText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_GlobalTeamBlock {
            __typename
            blockId
            title
            teammates {
              fieldGroupName
              teammate {
                __typename
                ... on WpTeammate {
                  title
                  acf_teammate {
                    goal
                    position
                    title
                    buttonPage {
                      ... on WpPage {
                        uri
                        title
                      }
                    }
                    buttonText
                  }
                  content
                  featuredImage {
                    node {
                      __typename
                      ... on WpMediaItem {
                        mediaItemUrl
                        localFile {
                          childImageSharp {
                              gatsbyImageData
                          }
                        }
                        altText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_GlobalFormBlock {
            __typename
            blockId
            fieldGroupName
            title
            subtitle
            image {
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              altText
            }
          }
          ... on WpPage_AcfContent_AcfContent_GlobalFounderBlock {
            __typename
            blockId
            fieldGroupName
            content
            title
            image {
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              altText
            }
          }
          ... on WpPage_AcfContent_AcfContent_GlobalBannerBlock {
            __typename
            blockId
            fieldGroupName
            bgColor
            image {
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              altText
            }
            title
            titleColor
            buttonPage {
              ... on WpPage {
                uri
                title
              }
            }
            buttonText
            buttonBg
            buttonTextColor
            borderRaduis
            classes
            layout
          }
          ... on WpPage_AcfContent_AcfContent_HomeHeroBlock {
            __typename
            blockId
            fieldGroupName
            title
            buttonPage {
              ... on WpPage {
                uri
                title
              }
            }
            buttonText
            bgLogo {
              mediaItemUrl
              localFile {
              childImageSharp {
                  gatsbyImageData
              }
              }
              altText
            }
            teamImage {
              mediaItemUrl
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
              altText
            }
            clients {
              logo {
                mediaItemUrl
                localFile {
                  childImageSharp {
                      gatsbyImageData
                  }
                }
                altText
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_HomeTestimonialsBlock {
            __typename
            blockId
            fieldGroupName
            title
            testimonials {
              fieldGroupName
              testimonial {
                __typename
                ... on WpTestimonial {
                  acf_testimonial {
                    position
                    stars
                  }
                  content
                  title
                  featuredImage {
                    node {
                      __typename
                      ... on WpMediaItem {
                        mediaItemUrl
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                        altText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_AboutHeroBlock {
            __typename
            badge
            blockId
            fieldGroupName
            content
            title
            subtitle
            teamImage {
              mediaItemUrl
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
              altText
            }
            teamImage {
              mediaItemUrl
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
              altText
            }
            avatar1 {
              mediaItemUrl
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
              altText
            }
            avatar2 {
              mediaItemUrl
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
              altText
            }
            avatar3 {
              mediaItemUrl
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
              altText
            }
          }
          ... on WpPage_AcfContent_AcfContent_AboutCtaBlock {
            __typename
            blockId
            fieldGroupName
            leftCta {
              backgroundColor
              buttonText
              page {
                ... on WpPage {
                  uri
                  title
                }
              }
              title
              titleColor
              image {
                mediaItemUrl
                localFile {
                  childImageSharp {
                      gatsbyImageData
                  }
                }
                altText
              }
            }
            rightCta {
              backgroundColor
              buttonText
              page {
                ... on WpPage {
                  uri
                  title
                }
              }
              title
              titleColor
              image {
                mediaItemUrl
                localFile {
                  childImageSharp {
                      gatsbyImageData
                  }
                }
                altText
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_TeamMediaBlock {
            __typename
            blockId
            fieldGroupName
            type
            youtubeVideoId
            title
            image {
              mediaItemUrl
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
              altText
            }
          }
          ... on WpPage_AcfContent_AcfContent_TeamTeamBlock {
            __typename
            blockId
            fieldGroupName
            title
            teammates {
              teammate {
                ... on WpTeammate {
                  title
                  featuredImage {
                    node {
                      __typename
                      ... on WpMediaItem {
                        mediaItemUrl
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                        altText
                      }
                    }
                  }
                  acf_teammate {
                    position
                    color
                    gridImage {
                      ... on WpMediaItem {
                        mediaItemUrl
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                        altText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_ServicesHeroBlock {
            __typename
            blockId
            fieldGroupName
            content
            title
            buttonText
            buttonPage {
              ... on WpPage {
                uri
                title
              }
            }
            image {
              mediaItemUrl
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
              altText
            }
            imageMobile {
              mediaItemUrl
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
              altText
            }
          }
          ... on WpPage_AcfContent_AcfContent_ServicesPartnersBlock {
            __typename
            blockId
            fieldGroupName
            content
            partners {
              image {
                mediaItemUrl
                localFile {
                  childImageSharp {
                      gatsbyImageData
                  }
                }
                altText
              }
            }
            title
          }
          ... on WpPage_AcfContent_AcfContent_ServicesWorkProcess {
            __typename
            blockId
            fieldGroupName
            title
            content
            mobileGif {
              mediaItemUrl
              localFile {
                url
              }
              altText
            }
            steps {
              bgColor
              text
              textColor
              services {
                service {
                  ... on WpService {
                    title
                    acf_services {
                      cardBlock {
                        title
                        logo {
                          mediaItemUrl
                          localFile {
                            childImageSharp {
                                gatsbyImageData
                            }
                          }
                          altText
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_CareersHeroBlock {
            __typename
            blockId
            fieldGroupName
            title
            bgDesktopImage {
              mediaItemUrl
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
              altText
            }
            buttonPage {
              ... on WpPage {
                uri
                title
              }
            }
            buttonText
          }
          ... on WpPage_AcfContent_AcfContent_CareersBasedBlock {
            __typename
            badge
            blockId
            content
            title
            image {
              mediaItemUrl
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
              altText
            }
            youtubeVideoId
          }
          ... on WpPage_AcfContent_AcfContent_CareersBelieveBlock {
            __typename
            blockId
            fieldGroupName
            title
            image {
              mediaItemUrl
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
              altText
            }
            items {
              title
              content
              icon {
                mediaItemUrl
                localFile {
                  childImageSharp {
                      gatsbyImageData
                  }
                }
                altText
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_CareersBannerBlock {
            __typename
            backgroundColor
            blockId
            content
            title
          }
          ... on WpPage_AcfContent_AcfContent_CareersBenefitsBlock {
            __typename
            blockId
            content
            title
            items {
              title
            }
            image {
              mediaItemUrl
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
              altText
            }
            imageMobile {
              mediaItemUrl
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
              altText
            }
          }
          ... on WpPage_AcfContent_AcfContent_ContactFormBlock {
            __typename
            blockId
            fieldGroupName
            formSubtitle
            formTitle
            subtitle
            title
            image {
              mediaItemUrl
              localFile {
                childImageSharp {
                    gatsbyImageData
                }
              }
              altText
            }
          }
          ... on WpPage_AcfContent_AcfContent_FmpHeroBlock {
            __typename
            blockId
            fieldGroupName
            title
            buttonPage {
              ... on WpPage {
                uri
                title
              }
            }
            buttonText
          }
          ... on WpPage_AcfContent_AcfContent_FmpInfoBlock {
            __typename
            fieldGroupName
            blockId
            items {
              title
              content
              image {
                mediaItemUrl
                localFile {
                  childImageSharp {
                      gatsbyImageData
                  }
                }
                altText
              }
              types {
                name
              }
            }
          }
          ... on WpPage_AcfContent_AcfContent_FaqBlock {
            __typename
            blockId
            title
            items {
              question
              answer
            }
          }
          ... on WpPage_AcfContent_AcfContent_GlobalQuizBlock {
            __typename
            blockId
            fieldGroupName
            title
            steps {
              buttonText
              wpFields {
                description
                errorMessage
                name
                placeholder
                question
                required
                type
                answers {
                  label
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`
