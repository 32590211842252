import parse from "html-react-parser";
import React from "react";
import * as s from "./GlobalQuizBlock.module.css";
import ThankYou from './../ThankYou/ThankYou';

const GlobalQuizBlock = React.forwardRef(({ block }, ref) => {

  const data = block.steps;
  let initialQuizData = {};

  data.forEach((item) => {
    if (item.wpFields?.length > 0) {
      item.wpFields?.forEach((f) => {
        if (!!f.name) {
          if ('checkbox' === f.type) {
            initialQuizData[f.name] = [];
          } else {
            initialQuizData[f.name] = '';
          }
        }
      });
    }
  });
  const [quizData, setQuizData] = React.useState(initialQuizData);

  let initialActiveQuizSlideIndex = null;
  if (data?.length > 0) {
    initialActiveQuizSlideIndex = 0;
  }
  const [activeQuizSlideIndex, setActiveQuizSlideIndex] = React.useState(initialActiveQuizSlideIndex);

  const [sended, setSended] = React.useState(null);
  const [errors, setErrors] = React.useState([]);

  const handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    let value = "";
    switch (target.type) {
      case 'checkbox':
        let oldValue = quizData[name];
        if (target.checked) {
          oldValue.push(target.value);
          value = oldValue;
        } else {
          value = oldValue.filter((item) => item != target.value);
        }
        break;
      default:
        value = target.value;
        break;
    }

    let newQuizData = { ...quizData };
    newQuizData[name] = value;
    setQuizData(newQuizData);
  }

  const submitForm = () => {
    setSended(true);


    let payload = {
      event: 'formSubmit',
      type: 'marketing - plan',
      ...quizData
    };
    // console.log(payload);
    window.dataLayer.push({ ...payload });

    const requestOptions = {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(payload),
    };
    try {
      fetch("https://hooks.zapier.com/hooks/catch/6831474/b0trvtr/", requestOptions);
    } catch (error) {
      console.log(error)
    }
  }

  const isValidActiveQuizSlide = () => {
    let isValid = true;
    let fields = data[activeQuizSlideIndex]?.wpFields;
    let newErrors = [];
    fields?.forEach((f, i) => {
      let fieldValid = true;
      switch (f.type) {
        case 'checkbox':
          if (undefined !== f.required && true === f.required) {
            if (!quizData[f.name]?.length) {
              fieldValid = false;
            }
          }
          break;
        case 'email':
          if (undefined !== f.required && true === f.required) {
            if (!quizData[f.name]) {
              fieldValid = false;
            }
          }
          if (!!quizData[f.name] && !isEmailValid(quizData[f.name])) {
            fieldValid = false;
          }
          break;
        case 'url':
          if (undefined !== f.required && true === f.required) {
            if (!quizData[f.name]) {
              fieldValid = false;
            }
          }
          if (!!quizData[f.name] && !isUrlValid(quizData[f.name])) {
            fieldValid = false;
          }
          break;
        default:
          if (undefined !== f.required && true === f.required) {
            if (!quizData[f.name]) {
              fieldValid = false;
            }
          }
          break;
      }
      if (!fieldValid) {
        isValid = false;
        newErrors.push(f.errorMessage);
      }
    });
    setErrors(newErrors);
    // isValid = false;
    return isValid;
  }

  const isEmailValid = (email) => {
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }

  const isUrlValid = (url) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(url);
  }

  const nextSlide = (event) => {
    // console.log(quizData);
    let newIndex = activeQuizSlideIndex + 1;
    if (isValidActiveQuizSlide()) {
      if (!!data[newIndex]) {
        setActiveQuizSlideIndex(newIndex);
      } else {
        submitForm();
      }
    }
  }


  const getQuizBlockLayout = (item, qi) => {
    return <div className={`${s.quizBlock}`} key={`quiz-block-${qi}`}>
      <div className={`${s.form}`}>
        {item.wpFields?.length > 0 &&
          item.wpFields?.map((field, fi) => {
            return getFieldLayout(field, fi, qi);
          })
        }
        {!!item.buttonText &&
          <button onClick={nextSlide}>{item.buttonText}</button>
        }
        {!item.buttonText &&
          <button onClick={nextSlide}>{qi + 1 == data.length ? 'Submit' : 'Next'}</button>
        }
        {errors?.length > 0 &&
          <div className={`mt-3 d-flex flex-column`}
            style={{ 'gap': '4px', 'maxWidth': 'max-content' }}>
            {errors?.map((err, erri) => {
              return <div className={`error-message`} key={`error-${erri}`}>{err}</div>
            })}
          </div>
        }
      </div>
    </div>
  };

  const getFieldLayout = (field, fi, qi) => {
    let fieldLayout = null;
    switch (field.type) {
      case 'checkbox':
        fieldLayout = getCheckboxGroupLayout(field, fi, qi);
        break;
      case 'radio':
        fieldLayout = getRadioGroupLayout(field, fi, qi);
        break;
      case 'textarea':
        fieldLayout = getTextareaLayout(field, fi, qi);
        break;
      case 'email':
        fieldLayout = getEmailInputLayout(field, fi, qi);
        break;
      case 'url':
      case 'text':
      default:
        fieldLayout = getTextInputLayout(field, fi, qi);
        break;
    }

    return <div key={`quiz-field-${qi}-${fi}`} className="mt-3">
      {!!field.question &&
        <h3>{parse(field.question)}</h3>
      }
      {!!field.description &&
        <h4>{parse(field.description)}</h4>
      }
      <div className={`${s.inputsWrapper}`}>
        {fieldLayout}
      </div>
    </div>
  }

  const getCheckboxGroupLayout = (field, fi, qi) => {
    return field.answers?.map((a, ai) => {
      return <div className={`${s.radioGroup}`} key={`quiz-input-${qi}-${fi}-${ai}`}>
        <label className="d-flex align-items-start">
          <input
            type="checkbox"
            value={a.value}
            name={field.name}
            checked={quizData[field.name].includes(a.value)}
            onChange={handleInputChange} />
          <span>{a.label}</span>
        </label>
      </div>
    })
  }
  const getRadioGroupLayout = (field, fi, qi) => {
    return field.answers?.map((a, ai) => {
      return <div className={`${s.radioGroup}`} key={`quiz-input-${qi}-${fi}-${ai}`}>
        <label className="d-flex align-items-start">
          <input
            type="radio"
            value={a.value}
            name={field.name}
            checked={quizData[field.name] === a.value}
            onChange={handleInputChange} />
          <span>{a.label}</span>
        </label>
      </div>
    })
  }

  const getTextareaLayout = (field, fi, qi) => {
    return <div className={`${s.textareaGroup}`}>
      <textarea onChange={handleInputChange} name={field.name} placeholder={field.placeholder}></textarea>
    </div>
  }
  const getTextInputLayout = (field, fi, qi) => {
    return <div className={`${s.inputGroup}`}>
      <input type="text" onChange={handleInputChange} name={field.name} placeholder={field.placeholder} />
    </div>
  }
  const getEmailInputLayout = (field, fi, qi) => {
    return <div className={`${s.inputGroup}`}>
      <input type="email" onChange={handleInputChange} name={field.name} placeholder={field.placeholder} />
    </div>
  }

  return (
    <>
      <section className={`${s.quiz}`} ref={ref}>
        <div className="container">
          {null !== sended &&
            <>
              {
                true === sended &&
                <ThankYou showCalendlyForm="true" formData={{
                  name: quizData.fullName,
                  email: quizData.email,
                }} />
              }
              {false === sended &&
                <div className={`${s.thankYou} text-center`}>
                  <>
                    <h3>Oops!</h3>
                    <h5>Something went wrong! <br />Please try again later.</h5>
                  </>
                </div>
              }
            </>
          }
          {null === sended &&
            <>
              {(null === sended && null !== activeQuizSlideIndex) &&
                <div className={`${s.step}`}>STEP {activeQuizSlideIndex + 1}</div>
              }
              {!!block?.title &&
                <h2>{parse(block.title)}</h2>
              }
              {null !== activeQuizSlideIndex &&
                getQuizBlockLayout(data[activeQuizSlideIndex], activeQuizSlideIndex)
              }
            </>
          }
        </div>
      </section>
    </>
  );
});

export default GlobalQuizBlock;
