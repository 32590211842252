import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./ServicesPartnersBlock.module.css";

const ServicesPartnersBlock = ({ block }) => {

  const partners = block.partners.reduce(function (filtered, p) {
    let image = {
      data: p.image?.localFile?.childImageSharp?.gatsbyImageData,
      alt: p.image?.altText || ``,
    }
    if (!!image.data) {
      filtered.push(image);
    }
    return filtered;
  }, []);

  return (
    <>
      <section className={`${s.servicesPartnersBlock}`}>
        <div className="container">
          <div className={`row`}>
            <div className="col-12">
              {!!block.title &&
                <div className={`${s.title}`}>{parse(block.title)}</div>
              }
              {!!block.content &&
                <div className={`${s.content}`}>{parse(block.content)}</div>
              }
            </div>
            <div className="col-12">
              <div className={`${s.row} row align-items-center justify-content-center`}>
                {(!!partners && partners.length > 0) &&
                  partners.map((image, i) => {
                    return <GatsbyImage
                      loading="eager"
                      placeholder="dominantColor"
                      image={image.data}
                      alt={image.alt}
                      className={`${s.partnerLogo}`}
                      key={`parner-inage-${i}`}/>
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesPartnersBlock;
