import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./GlobalFounderBlock.module.css";

const GlobalFounderBlock = ({ block }) => {
  const image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };
  return (
    <>
      <section className={`${s.aboutFounder}`}>
        <div className="container">
          <div className={`${s.founderBlock}`}>
            <div className="row">
              <div className={`${s.secTitle} col`}>
                {parse(block.title)}
              </div>
            </div>
            <div className={`${s.spacingLeft}`}>
              <div className="row">
                <div className="col-12 col-md-5 order-2 order-md-1">
                  <div className={`${s.founderImageBlock}`}>
                    <div className={`${s.blockAfter}`}>
                      <GatsbyImage
                        loading="eager"
                        placeholder="dominantColor"
                        image={image.data}
                        alt={image.alt}
                        className={`${s.founderImage}`}/>
                    </div>

                  </div>
                </div>
                <div className="col-12 col-md-7 order-1 order-md-2">
                  <div className={`${s.founderIntro}`}>
                    {parse(block.content)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GlobalFounderBlock;
