import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./GlobalMissionVisionBlock.module.css";

const GlobalMissionVisionBlock = ({ block }) => {
  return (
    <>
      <section className={`${s.ourMisVis}`}>
        <div className="container">
          <div className={`${s.spacingLeft}`}>
            <div className={`${s.rightArrowVis}`}>
              <div className="row">
                <div className="col-12 col-md-8 col-lg-9 col-xl-8">
                  <div className={`${s.ourMissionBlock}`}>
                    {parse(block.mission)}
                  </div>
                </div>
                <div className="col-12 col-md-7 offset-md-5">
                  <div className={`${s.ourVisionBlock}`}>
                    {parse(block.vision)}
                    <StaticImage
                      // src="../../assets/images/blocks/GlobalMissionVisionBlock/vision-star.svg"
                      src="./images/vision-star.svg"
                      alt="star"
                      className={`${s.star} d-none d-xl-block`}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GlobalMissionVisionBlock;
