// extracted by mini-css-extract-plugin
export var active = "SingleVacancyBlock-module--active--32efc";
export var arrowButton = "SingleVacancyBlock-module--arrow-button--dcbc0";
export var colored = "SingleVacancyBlock-module--colored--ded8a";
export var description = "SingleVacancyBlock-module--description--70f88";
export var ourServiceItem = "SingleVacancyBlock-module--our-service-item--e6497";
export var ourServices = "SingleVacancyBlock-module--our-services--f22fe";
export var owlItem = "SingleVacancyBlock-module--owl-item--1445f";
export var owlStageOuter = "SingleVacancyBlock-module--owl-stage-outer--dadd1";
export var seoDesc = "SingleVacancyBlock-module--seo-desc--bd150";
export var serviceIconBlock = "SingleVacancyBlock-module--service-icon-block--179a0";
export var serviceIconImage = "SingleVacancyBlock-module--service-icon-image--6e9af";
export var serviceItemBlock = "SingleVacancyBlock-module--service-item-block--3ee06";
export var servicesBlock = "SingleVacancyBlock-module--services-block--d2e42";