import parse from "html-react-parser";
import React from "react";
import Slider from "react-slick";
import * as s from "./GlobalVacanciesBlock.module.css";
import SingleVacancyBlock from "./SingleVacancyBlock";

const GlobalVacanciesBlock = React.forwardRef(({ block }, ref) => {

  const [active, setActive] = React.useState(0);

  const sliderSettings = {
    dots: true,
    arrows: false,
    slidesToShow: 3,
    infinite: true,
    // centerMode: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 1199.9,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '32px',
          variableWidth: true,
        },
      },
      {
        breakpoint: 767.9,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '16px',
          variableWidth: true,
          // arrows: false,
        },
      },
    ],
  };

  const SlickAfterChangeHandler = (ioldIndex, newIndex) => {
    setActive(newIndex);
  }

  return (
    <>
      <section className={`${s.ourServices}`} ref={ref}>
        <div className="container">
          {!!block.title &&
            parse(block.title)
          }
          <div className={`${s.servicesBlock} d-none d-xl-flex flex-wrap justify-content-between justify-content-lg-evenly`}>
            {(!!block.items && block.items.length > 0) &&
              block.items.map((item, i) =>
                <SingleVacancyBlock block={item.item} active={false} key={`vacancy-item-${i}`} />
              )
            }
          </div>

        </div>
        <div className={`${s.servicesBlock} d-flex d-xl-none flex-column owl-carousel service-carousel`}>
          <Slider
            {...sliderSettings}
            beforeChange={SlickAfterChangeHandler}
          >
            {(!!block.items && block.items.length > 0) &&
              block.items.map((item, i) =>
                <SingleVacancyBlock block={item.item} active={(active == i)} key={`vacancy-slider-item-${i}`} />
              )
            }
          </Slider>
        </div>
      </section>
    </>
  );
});

export default GlobalVacanciesBlock;
