import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./AboutCtaBlock.module.css";

const AboutCtaBlock = ({ block }) => {
  const leftCtaImage = {
    data: block.leftCta.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.leftCta.image?.altText || ``,
  };
  const rightCtaImage = {
    data: block.rightCta.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.rightCta.image?.altText || ``,
  };

  let leftTitle = parse(block.leftCta.title);
  let rightTitle = parse(block.rightCta.title);

  if ( block.leftCta.title.includes("%button%") ) {
    let arr = block.leftCta.title.split("%button%", 2);
    let leftLink = <Link to={block.leftCta.page.uri} className={`${s.appContButton} ${s.applyButton}`}>
      {!!block.leftCta.buttonText ? block.leftCta.buttonText : block.leftCta.page.title}
    </Link>;
    leftTitle = <>{parse(arr[0])} {leftLink} {parse(arr[1])}</>;
  }

  if ( block.rightCta.title.includes("%button%") ) {
    let arr = block.rightCta.title.split("%button%", 2);
    let rightLink = <Link to={block.rightCta.page.uri} className={`${s.appContButton} ${s.applyButton}`}>
      {!!block.rightCta.buttonText ? block.rightCta.buttonText : block.rightCta.page.title}
    </Link>;
    rightTitle = <>{parse(arr[0])} {rightLink} {parse(arr[1])}</>;
  }

  let blockStyles = {};
  if (!!block.leftCta.backgroundColor) {
    blockStyles["--ctaBlock-gradient-start"] = block.leftCta.backgroundColor;
  }
  if (!!block.rightCta.backgroundColor) {
    blockStyles["--ctaBlock-gradient-end"] = block.rightCta.backgroundColor;
  }

  return (
    <>
      <section className={`${s.joinTeamAccelerateGrowth}`} style={blockStyles}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className={`${s.joinBlock}`}>
                <h2 style={{"color": block.leftCta.titleColor}}>{leftTitle}</h2>
                {!!leftCtaImage.data &&
                  <GatsbyImage
                    loading="eager"
                    placeholder="dominantColor"
                    image={leftCtaImage.data}
                    alt={leftCtaImage.alt}
                    className={`${s.joinImage}`} />
                }
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className={`${s.accelerateBlock}`}>
                <h2 style={{"color": block.rightCta.titleColor}}>{rightTitle}</h2>

                {!!rightCtaImage.data &&
                  <GatsbyImage
                    loading="eager"
                    placeholder="dominantColor"
                    image={rightCtaImage.data}
                    alt={rightCtaImage.alt}
                    className={`${s.accelerateImage}`} />
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutCtaBlock;
