import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import Slider from "react-slick";
import * as s from "./PartnersBlock.module.css";

const PartnersBlock = ({ block }) => {
  const bgLogo = {
    data: block.bgLogo?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.bgLogo?.altText || ``,
  };

  const sliderSettings = {
    dots: true,
    arrows: false,
    // variableWidth: true,
    slidesToShow: 5,
    infinite: true,
    // centerMode: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1199.9,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 575.9,
        settings: {
          slidesToShow: 3,
          // arrows: false,
        },
      },
    ],
  };

  const clients = block.clients.filter((client) => {
    let image = {
      data: client.logo?.localFile?.childImageSharp?.gatsbyImageData,
      alt: client.logo?.altText || ``,
    }
    return !!image.data;
  });

  return (
    <>
      <section className={`${s.partners}`}>
        <div className="container d-none d-lg-block">
          <div className={`${s.partnersBlock} d-flex justify-content-center align-items-center`}>
            {(!!clients && clients.length > 0) &&
              <>
                {clients.map((client) => {
                  let image = {
                    data: client.logo?.localFile?.childImageSharp?.gatsbyImageData,
                    alt: client.logo?.altText || ``,
                  }
                  return <div className={`${s.partnersItem}`} key={client.logo.mediaItemUrl}>
                    <GatsbyImage
                      loading="eager"
                      placeholder="dominantColor"
                      image={image.data}
                      alt={image.alt} />
                  </div>
                })}
              </>
            }
          </div>
        </div>
        <div className="d-block d-lg-none">
          {(!!clients && clients.length > 0) &&
            <div className={s.partnersCarouselBlock}>
              <Slider {...sliderSettings}>
                {clients.map((client) => {
                  let image = {
                    data: client.logo?.localFile?.childImageSharp?.gatsbyImageData,
                    alt: client.logo?.altText || ``,
                  }
                  return <div className={`${s.partnersItem}`} key={client.logo.mediaItemUrl}>
                    <GatsbyImage
                      loading="eager"
                      placeholder="dominantColor"
                      image={image.data}
                      alt={image.alt} />
                  </div>
                })}
              </Slider>
            </div>
          }
        </div>
      </section>
    </>
  );
};

export default PartnersBlock;
