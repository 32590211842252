import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./CareersBasedBlock.module.css";
import YoutubeVideo from './../youtubeVideo/youtubeVideo';

const CareersBasedBlock = ({ block }) => {
  const image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };

  let placeholderImageUrl = "";
  if (!!block.youtubeVideoId) {
    if (!!image.data) {
      placeholderImageUrl = image.data.images.fallback.src
    } else {
      placeholderImageUrl = `https://img.youtube.com/vi/${block.youtubeVideoId}/maxresdefault.jpg`;
    }
  }

  return (
    <>
      <section className={`${s.based}`}>
        <div className="container">
          <div>
            {!!block.badge &&
              <div className={`${s.sinceButton} d-none d-md-inline-flex align-items-center`}>
                {/* <img src="./img/Star-yellow.png" alt=""/> */}
                <StaticImage alt="star-yellow" src="./images/star-yellow.png" />
                <p>{block.badge}</p>
              </div>
            }
          </div>
          <div className="row">
            <div className="col-md-6">
              {!!block.title && parse(block.title)}
            </div>
            <div className="col-md-6">
              <div className="d-flex align-items-center h-100">
                {!!block.content &&
                  parse(block.content)
                }
                {/* <p>Azarian Growth Agency is a marketing partner for high-growth companies. We are a
                  remote-first
                  company with a team that iterates fast, learns, and repeats.
                </p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {!!block.youtubeVideoId &&
                <YoutubeVideo imageUrl={placeholderImageUrl} videoId={block.youtubeVideoId} />
              }
              {(!block.youtubeVideoId && !!image.data) &&
                <GatsbyImage
                  loading="eager"
                  placeholder="dominantColor"
                  image={image.data}
                  alt={image.alt}
                  className={`mw-100`} />
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareersBasedBlock;
