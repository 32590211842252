import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./CareersBelieveBlock.module.css";

const CareersBelieveBlock = ({ block }) => {
  const image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };

  return (
    <>
      <section className={`${s.weBelive}`}>
        <div className="container">
          {!!block.title && parse(block.title)}

          <div className="row">
            <div className="col-12 col-lg-6">
              {block.items?.length > 0 &&
                block.items?.map((item, i) => {
                  let icon = {
                    data: item.icon?.localFile?.childImageSharp?.gatsbyImageData,
                    alt: item.icon?.altText || ``,
                  }
                  return <div className={`${s.beliveItem} d-flex`} key={`believe-item-${i}`}>
                    <div>
                      {!!icon.data &&
                        <GatsbyImage
                          loading="eager"
                          placeholder="dominantColor"
                          image={icon.data}
                          alt={icon.alt}
                          className={`${s.beliveItemIcon}`} />
                      }
                      {/* <img src="./img/TrendingUpOutline.svg" alt="" /> */}
                    </div>
                    <div>
                      {!!item.title && <h3>{item.title}</h3>}
                      {!!item.content && parse(item.content)}
                      {/* <p>We are a team that iterates fast, learns, and repeats. We celebrate our success and
                        learn from our failures. Growth and learning are in our DNA!
                      </p> */}
                    </div>
                  </div>
                })
              }
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              {!!image.data &&
                <GatsbyImage
                  loading="eager"
                  placeholder="dominantColor"
                  image={image.data}
                  alt={image.alt}
                  className={`mw-100`} />
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CareersBelieveBlock;
