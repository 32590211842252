import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./ContactFormBlock.module.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import ThankYou from "./../ThankYou/ThankYou";

const ContactFormBlock = ({ block }) => {

  const [name, setName] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [messageSent, setMessageSent] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  const image = {
    data: block.image?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.image?.altText || ``,
  };


  let blockStyles = {};
  if (!!image.data) {
    // blockStyles["backgroundImage"] = `url(${image.data.images.fallback.src})`;
  }


  const submitForm = () => {
    if (isValidForm()) {
      setMessageSent(true);
      let payload = {
        event: 'formSubmit',
        type: 'contact-us',
        fullName: name,
        company: company,
        email: email,
        Phone: phone,
        message: message,
      };
      // console.log(payload);
      window.dataLayer.push({ ...payload });

      const requestOptions = {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(payload),
      };
      try {
        fetch("https://hooks.zapier.com/hooks/catch/6831474/b0tib3e/", requestOptions);
      } catch (error) {
        console.log(error)
      }
    }
  }

  const isValidForm = () => {
    let isValid = true;
    let newErrors = [];

    if (!name?.length) {
      isValid = false;
      newErrors.push('Please enter a Name');
    }

    if (!company?.length) {
      isValid = false;
      newErrors.push('Please enter a Company Name');
    }

    if (!isEmailValid(email)) {
      isValid = false;
      newErrors.push('Please enter a valid Email');
    }

    if (!phone?.length) {
      isValid = false;
      newErrors.push('Please enter a Phone');
    }

    if (!message?.length) {
      isValid = false;
      newErrors.push('Please enter a Message');
    }



    setErrors(newErrors);
    // isValid = false;
    return isValid;
  }

  const isEmailValid = (email) => {
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }


  return (
    <>
      <section className={`${s.contact} ${messageSent ? s.noBg : ''}`} style={blockStyles}>
        {messageSent && <div className="container">
          <ThankYou showCalendlyForm="true" formData={{
            name: name,
            email: email,
          }} />
        </div>
        }
        {!messageSent &&
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-7 col-xl-6 offset-md-5 offset-xl-6">
                {/* <h2 className={`${s.formTitle}`}>{block.formTitle}</h2>
                <p className={`${s.formSubtitle}`}>{block.formSubtitle}</p> */}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-5 col-xl-6">
                <div className={`${s.contactTitleBlock}`}>
                  <h1>{block.title}</h1>
                  <p>{block.subtitle}</p>
                </div>
                {!!image.data &&
                  <div className="pt-5">
                    <GatsbyImage
                      loading="eager"
                      placeholder="dominantColor"
                      image={image.data}
                      alt={image.alt}
                      className={`w-100 mt-5 d-none d-md-block`} />
                  </div>
                }
              </div>
              <div className="col-12 col-md-7 col-xl-6">
                <h2 className={`${s.formTitle}`}>{block.formTitle}</h2>
                <p className={`${s.formSubtitle}`}>{block.formSubtitle}</p>
                <form action="" className={`${s.form} form`}>
                  <div className={`${s.labelInputGroup}`}>
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={e => setName(e.target.value)}
                      placeholder="Enter your first name" />
                  </div>
                  <div className={`${s.labelInputGroup}`}>
                    <label htmlFor="company">Company</label>
                    <input
                      type="text"
                      name="company"
                      value={company}
                      onChange={e => setCompany(e.target.value)}
                      placeholder="Enter your company name" />
                  </div>
                  <div className={`${s.labelInputGroup} w-100`}>
                    <label htmlFor="email">Your Email</label>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      placeholder="Enter your email" />
                  </div>
                  <div className={`${s.labelInputGroup} w-100`}>
                    <label htmlFor="phone-number">Phone number</label>
                    <PhoneInput
                      defaultCountry="US"
                      placeholder="+1234567890"
                      value={phone}
                      onChange={setPhone}
                      required
                      name="phone"
                      className={`phone-number-input`}
                    />
                  </div>
                  <div className={`${s.labelInputGroup} w-100`}>
                    <label htmlFor="email">Message</label>
                    <textarea
                      name="message"
                      placeholder="Enter your message"
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      rows="5"></textarea>
                  </div>
                  <input type="button" value="Send" onClick={submitForm} className={`${s.sendButton}`} />
                </form>
                {errors?.length > 0 &&
                  <div className={`mt-3 d-flex flex-column`}
                    style={{ 'gap': '4px', 'maxWidth': 'max-content' }}>
                    {errors?.map((err, erri) => {
                      return <div className={`error-message`} key={`error-${erri}`}>{err}</div>
                    })}
                  </div>
                }
                {!!image.data &&
                  <GatsbyImage
                    loading="eager"
                    placeholder="dominantColor"
                    image={image.data}
                    alt={image.alt}
                    className={`w-100 mt-4 d-md-none`} />
                }
              </div>
            </div>
          </div>
        }
      </section>
    </>
  );
};

export default ContactFormBlock;
