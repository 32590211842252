import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./AboutHeroBlock.module.css";

const AboutHeroBlock = ({ block }) => {
  const avatar1 = {
    data: block.avatar1?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.avatar1?.altText || ``,
  };
  const avatar2 = {
    data: block.avatar2?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.avatar2?.altText || ``,
  };
  const avatar3 = {
    data: block.avatar3?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.avatar3?.altText || ``,
  };
  const groupImage = {
    data: block.teamImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.teamImage?.altText || ``,
  };

  return (
    <>
      <section className={`${s.aboutTopBlock}`}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7 col-xl-7">
              <div className={`${s.aboutTopBlockInfo}`}>
                <div className={`${s.sinceButton} d-inline-flex d-md-none align-items-center`}>
                  <StaticImage
                    src="./images/star.svg"
                    alt="star"
                  />
                  <span>{block.badge}</span>
                </div>
                <div className="d-flex align-items-center">
                  <div className={`${s.secTitle}`}>{parse(block.title)}</div>
                  <div className={`${s.sinceButton} d-none d-md-inline-flex align-items-center`}>
                    <StaticImage
                      src="./images/star.svg"
                      alt="star"
                    />
                    <span>{block.badge}</span>
                  </div>
                </div>
                <h2 className={`${s.subTitle}`}>{block.subtitle}</h2>
                {!!groupImage.data &&
                  <GatsbyImage
                    loading="eager"
                    placeholder="dominantColor"
                    image={groupImage.data}
                    alt={groupImage.alt}
                    className={`${s.groupImage} d-block d-md-none group-image w-100`} />
                }
                <div className={`${s.aboutDesc} d-block d-md-none`}>
                  <StaticImage
                    src="./images/DotOrnament.svg"
                    className={`${s.dots} dots`}
                    alt="DotOrnament"
                  />
                  <h3>About</h3>
                  {parse(block.content)}
                  {/* <button
                    className="d-inline-flex d-md-none btn btn-primary align-items-center hide-button"
                    type="button" data-bs-toggle="collapse" data-bs-target="#collapseAboutTop"
                    aria-expanded="false" aria-controls="collapseAboutTop">
                    Learn More <StaticImage
                      src="./images/arrow-right.svg"
                      alt="arrow-right"
                    />
                  </button>
                  <div className="collapse" id="collapseAboutTop">
                    <p>
                    </p>
                  </div> */}
                </div>
                <div className="d-none d-md-block">
                  {parse(block.content)}
                </div>
              </div>
            </div>
            <div className="col-md-5 col-xl-5 d-none d-md-block">
              <div className={`${s.aboutTopBlockAvatars}`}>
                {!!avatar1.data &&
                  <GatsbyImage
                    loading="eager"
                    placeholder="dominantColor"
                    image={avatar1.data}
                    alt={avatar1.alt}
                    className={`${s.smallAvatar}`} />
                }
                {!!avatar2.data &&
                  <GatsbyImage
                    loading="eager"
                    placeholder="dominantColor"
                    image={avatar2.data}
                    alt={avatar2.alt}
                    className={`${s.bigAvatar}`} />
                }
                {!!avatar3.data &&
                  <GatsbyImage
                    loading="eager"
                    placeholder="dominantColor"
                    image={avatar3.data}
                    alt={avatar3.alt}
                    className={`${s.midAvatar}`} />
                }
                {!!groupImage.data &&
                  <GatsbyImage
                    loading="eager"
                    placeholder="dominantColor"
                    image={groupImage.data}
                    alt={groupImage.alt}
                    className={`${s.groupImage}`} />
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutHeroBlock;
