import parse from 'html-react-parser';
import React from "react";
import Slider from "react-slick";
import SingleTeammateBlock from "./SingleTeammateBlock";
import * as s from "./TeamTeamBlock.module.css";
const chunk = require(`lodash/chunk`)

const TeamTeamBlock = ({ block }) => {

  const [active, setActive] = React.useState(0);

  const sliderSettings = {
    dots: true,
    arrows: false,
    slidesToShow: 3,
    infinite: true,
    // centerMode: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 1199.9,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '32px',
          variableWidth: true,
        },
      },
      {
        breakpoint: 767.9,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '16px',
          variableWidth: true,
          // arrows: false,
        },
      },
    ],
  };

  let teamChunks = chunk(block.teammates, 3);
  return (
    <>
      <section className={`${s.ourTeam}`}>
        <div className="container">
          <div className={`${s.titleBlock} d-flex justify-content-center`}>
            <h2 className="text-center">{!!block.title && parse(block.title)}</h2>
          </div>
        </div>
        <div className="container">
          <div className={`row d-none d-xl-flex`}>
            {(!!block.teammates && block.teammates.length > 0) &&
              block.teammates.map((item, i) =>
                <div className="col-xl-4" key={`team-item-${i}`} >
                  <SingleTeammateBlock block={item.teammate}/>
                </div>
              )
            }
          </div>
        </div>
        <div className={`d-xl-none service-carousel`}>
          {(!!teamChunks && teamChunks.length > 0) &&
            teamChunks.map((teammates, si) => {
              return <div key={`team-slider-${si}`} className="pb-4 mb-4">
                <Slider {...sliderSettings}>
                  {(!!teammates && teammates.length > 0) &&
                    teammates.map((item, i) =>
                      <SingleTeammateBlock block={item.teammate} key={`team-slider-item-${i}`} />
                    )
                  }
                </Slider>
              </div>
            })
          }
        </div>
      </section>
    </>
  );
};

export default TeamTeamBlock;
