// extracted by mini-css-extract-plugin
export var aboutDesc = "AboutHeroBlock-module--about-desc--87684";
export var aboutTopBlock = "AboutHeroBlock-module--about-top-block--3fa98";
export var aboutTopBlockAvatars = "AboutHeroBlock-module--about-top-block-avatars--99ac5";
export var aboutTopBlockInfo = "AboutHeroBlock-module--about-top-block-info--dbfbe";
export var bigAvatar = "AboutHeroBlock-module--big-avatar--6a270";
export var dots = "AboutHeroBlock-module--dots--4e33b";
export var firstChild = "AboutHeroBlock-module--first-child--33b55";
export var groupImage = "AboutHeroBlock-module--group-image--5d63a";
export var hideButton = "AboutHeroBlock-module--hide-button--7d716";
export var midAvatar = "AboutHeroBlock-module--mid-avatar--0cf3d";
export var secTitle = "AboutHeroBlock-module--sec-title--52c3b";
export var secondChild = "AboutHeroBlock-module--second-child--79067";
export var sinceButton = "AboutHeroBlock-module--since-button--2ae3e";
export var smallAvatar = "AboutHeroBlock-module--small-avatar--5c115";
export var subTitle = "AboutHeroBlock-module--sub-title--5caee";