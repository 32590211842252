import { Link } from "gatsby";
import parse from "html-react-parser";
import React from "react";
import * as s from "./FMPHeroBlock.module.css";

const FMPHeroBlock = ({ block, targetRef, scrollToRef }) => {

  return (
    <>
      <section className={`${s.freeMarketingHeader}`}>
        <div className="container">
          <h1 className={`${s.secTitle}`}>{parse(block.title)}</h1>
          {!!targetRef &&
            <button onClick={() => scrollToRef(targetRef)} className={`${s.btn}`}>
              {!block.buttonText ? 'Schedule a call' : block.buttonText}
            </button>
          }
          {(!targetRef && !!block.buttonPage) &&
            <Link
              to={block.buttonPage.uri}
              className={`${s.btn}`}
            >{!block.buttonText ? block.buttonPage.title : block.buttonText}</Link>
          }
        </div>
      </section>
    </>
  );
};

export default FMPHeroBlock;
