import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import * as s from "./SingleVacancyBlock.module.css";

const SingleVacancyBlock = ({ block, active }) => {

  const data = block.acf_vacancies?.cardBlock;
  const logo = {
    data: data.logo?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data.logo?.altText || ``,
  };
  const hoverLogo = {
    data: data.hoverLogo?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data.hoverLogo?.altText || ``,
  };

  let blockStyles = {};
  if (!!data.color) {
    blockStyles["--single-service-color"] = data.color;
  }

  return (
    <>
      <Link to={block.uri} className={`${s.ourServiceItem} ${!active ? '' : s.active}`} style={blockStyles}>
        <div className={`${s.serviceItemBlock} d-flex flex-column align-items-center`}>
          <div className={`${s.serviceIconBlock}`}>
            {!!logo.data &&
              <GatsbyImage
                loading="eager"
                placeholder="dominantColor"
                image={logo.data}
                alt={logo.alt}
                width={58}
                height={58}
                className={`${s.serviceIconImage}`} />
            }
            {!!hoverLogo.data &&
              <GatsbyImage
                loading="eager"
                placeholder="dominantColor"
                image={hoverLogo.data}
                alt={hoverLogo.alt}
                width={58}
                height={58}
                className={`${s.serviceIconImage} ${s.colored}`} />
            }
          </div>
          <h3>{!!data.title ? data.title : block.title}</h3>
          <div className={`${s.description}`}>{!!data.description ? parse(data.description) : parse(block.excerpt)}</div>
          <button className={`${s.arrowButton}`}>
            <StaticImage
              loading="eager"
              placeholder="none"
              alt="arrow-right"
              width={27}
              height={27}
              src="./images/arrow-right.svg"
            />
          </button>
        </div>
      </Link>
    </>
  );
};

export default SingleVacancyBlock;
