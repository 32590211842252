import { Link } from "gatsby";
import parse from "html-react-parser";
import React from "react";
import * as s from "./CareersHeroBlock.module.css";

const CareersHeroBlock = ({ block, targetRef, scrollToRef }) => {

  const desktopImage = {
    data: block.bgDesktopImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.bgDesktopImage?.altText || ``,
  };

  let blockStyles = {};
  if (!!desktopImage.data) {
    blockStyles["backgroundImage"] = `url(${desktopImage.data.images.fallback.src})`;
  }

  return (
    <>
      <section className={`${s.careersTopBlock}`} style={blockStyles}>
        <div className="container">
          <div className={`d-flex justify-content-center align-items-center ${s.centered}`}></div>
          {!!block.title &&
            <h3>{parse(block.title)}</h3>
          }
          {!!targetRef &&
            <div className="text-center">
              <button onClick={() => scrollToRef(targetRef)} className={`${s.btn} btn`}>
                {!block.buttonText ? 'View Jobs' : block.buttonText}
              </button>
            </div>
          }
          {(!targetRef && !!block.buttonPage) &&
            <div className="text-center">
              <Link
                to={block.buttonPage.uri}
                className={`${s.btn} btn`}
              >{!block.buttonText ? block.buttonPage.title : block.buttonText}</Link>
            </div>
          }
        </div>
      </section>
    </>
  );
};

export default CareersHeroBlock;
