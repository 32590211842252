import { Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React from "react";
import PartnersBlock from "../PartnersBlock/PartnersBlock";
import * as s from "./HomeHeroBlock.module.css";

const HomeHeroBlock = ({ block }) => {
  const bgLogo = {
    data: block.bgLogo?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.bgLogo?.altText || ``,
  };
  const teamImage = {
    data: block.teamImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: block.teamImage?.altText || ``,
  };

  let blockStyles = {};
  if (!!bgLogo.data) {
    blockStyles["backgroundImage"] = `url(${bgLogo.data.images.fallback.src}), linear-gradient(#EBDFD3, #F5EDE6)`;
  } else {
    blockStyles["backgroundImage"] = `linear-gradient(#EBDFD3, #F5EDE6)`;
  }

  return (
    <>
      <section className={`${s.topBlock}`} style={blockStyles}>
        <div className="container">
          <div className={`${s.secTitle}`}>{parse(block.title)}</div>
          <div className={`d-flex justify-content-center`}>
            {!!block.buttonPage &&
              <Link
                to={block.buttonPage.uri}
                className={`${s.topBlockButton}`}
                >{!block.buttonText ? block.buttonPage.title : block.buttonText}</Link>
            }
          </div>
          <div className={`${s.imageBlock} d-flex justify-content-center`}>
            <div>
              <GatsbyImage
                loading="eager"
                placeholder="dominantColor"
                image={teamImage.data}
                alt={teamImage.alt} />
            </div>
          </div>
        </div>
      </section>
      <PartnersBlock block={block} />
    </>
  );
};

export default HomeHeroBlock;
