import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import * as s from "./SingleTeammateBlock.module.css";

const SingleTeammateBlock = ({ block, active }) => {

  const data = block.acf_teammate;

  const gridImage = {
    data: data.gridImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: data.gridImage?.altText || ``,
  };

  let style = {};
  if (!!data.color) {
    style["borderTopColor"] = data.color;
  }

  return (
    <>
      <div className={`${s.teammate}`}>
        {!!gridImage.data &&
          <GatsbyImage
            loading="eager"
            placeholder="dominantColor"
            image={gridImage.data}
            alt={gridImage.alt}
            width={300}
            height={365}
            className={``} />
        }
        <div className={`${s.info}`} style={style}>
          <h3>{block.title}</h3>
          {!!data.position &&
            <p className={`${s.position}`}>{data.position}</p>
          }
        </div>
      </div>
    </>
  );
};

export default SingleTeammateBlock;
